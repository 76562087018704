

































import {Component, Vue, Watch} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {AlertViewType} from '@/store/modules/Messages';

const messages = namespace('Messages');
@Component({})
export default class PopupSnackbar extends Vue {

  @messages.State
  popupEvent!: { html: string; alertType: AlertViewType };

  @messages.Action
  private setPopupHtml!: (html: string) => void;

  get isPopup(): boolean {
    return !!this.popupEvent.html;
  }

  get popup(): string {
    return this.popupEvent.html;
  }

  set popup(html: string) {
    this.setPopupHtml(html);
  }
}
