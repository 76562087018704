







import Vue from 'vue';
import PopupSnackbar from '@/components/PopupSnackbar.vue';

export default Vue.extend({
  name: 'App',

  components: { PopupSnackbar },

  data: () => ({
    //
  })
});
