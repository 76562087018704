



































import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { Message } from '@/types';
import { namespace } from 'vuex-class';
import MessageBubble from '@/components/chat/MessageBubble.vue';
import UserInput from '@/components/chat/UserInput.vue';
import InteractChatConfig from '@/store/modules/InteractChatConfig';
import ReplayAction from '@/components/ReplayAction.vue';
import { EventService } from '@/services/interact/EventService';

const messages = namespace('Messages');
const interactChatConfigNS = namespace('InteractChatConfig');

const InteractEventsNS = namespace('InteractEvents');

@Component({
  components: { ReplayAction, UserInput, MessageBubble }
})
export default class ChatView extends Vue {
  @messages.State
  public messages!: Array<Message>;

  @interactChatConfigNS.State
  public config!: InteractChatConfig;

  @InteractEventsNS.State
  eventServices!: Map<string, EventService>;

  private isAvailable = false;

  @Watch('messages')
  messageAdded() {
    if (this.messages.length > 0) {
      this.isAvailable = true;

      setTimeout(() => {
        try {
          const elem = document.getElementById('scrolled-content');
          const container = document.getElementById('scroll-target');
          if (elem && container) {
            container.scroll({
              behavior: 'smooth',
              top: Math.floor(elem.offsetHeight)
            });
          }
        } catch (e) {
          console.log('no elements yet');
        }
      }, 200);
    }
  }
}
