import axios from 'axios';

export interface DemoConfigItem {
  id: number;
  name: string;
  description: string;
  features: Array<{
    name: string;
    id: number;
    description: string;
  }>;
  configItems: Array<{
    name: string;
    id: number;
    value: string;
    description: string;
  }>;
}

export class DemoConfigService {
  async getConfig(configId: string): Promise<DemoConfigItem> {
    const res = await axios.get<DemoConfigItem>(
      'https://newdemocenter.democenter.jacadademos.com/api/agent-assistant-configs/' + configId
    );

    return res.data;
  }
}
