
























import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { InteractPageSection, InteractSectionChoice } from "@/types";


@Component({})
export default class QuestionInputElement extends Vue {
  @Prop({ required: true }) readonly section!: InteractPageSection;
  @Prop({ required: true }) readonly disabled!: boolean;

  private input = "";

  private rules: Array<any> = [];

  private getInputType(choice: InteractSectionChoice): string {
    switch (choice.textInput?.textInputFormat) {
      case "TEXT":
        return "text";
      case "DATE":
        return "date";
      case "CURRENCY":
        return "number";
      case "PASSWORD":
        return "password";
      default:
        return "text";
    }
  }

  private updateInputParameters() {
    this.$emit("updateInputParameters", this.section.id, this.input);
  }

  mounted() {
    if (this.section.sectionChoices[0].textInput!.isMandatory) {
      this.rules.push((v: any) => !!v || "Required");
    }
    this.input = this.section.sectionChoices[0].choiceValue;
    this.updateInputParameters();
  }
}
