











































































import { namespace } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import { ToolbarOptionEntry } from '@/store/modules/ToolbarOption';
import axios from 'axios';

const toolbarOption = namespace('ToolbarOption');

@Component({})
// eslint-disable-next-line no-undef
export default class Toolbar extends Vue {
  dialog = false;
  number = '';
  text = '';
  link = 'https://demosites.jacadademos.com/finance';

  @toolbarOption.State
  public enabledToolbarOptions!: Array<ToolbarOptionEntry>;

  @toolbarOption.State
  public telephonyActive!: boolean;

  @toolbarOption.Action
  public changeState!: (toolbarOption: ToolbarOptionEntry) => void;

  @toolbarOption.Action
  public setTelephonyActive!: (active: boolean) => void;

  mounted() {
    this.number = window.localStorage.getItem('number') || '';
    this.text = window.localStorage.getItem('text') || '';
    this.link = window.localStorage.getItem('link') || 'https://demosites.jacadademos.com/finance';
  }

  public get isRightBar() {
    return this.$route.params.side === 'right';
  }

  public buttonColor(toolbarOption: ToolbarOptionEntry) {
    switch (toolbarOption.icon) {
      case 'mdi-emoticon-happy-outline':
        return 'green';
      case 'mdi-emoticon-sad-outline':
        return 'red';
      default:
        return 'white';
    }
  }

  public selectedColor(name: string) {
    switch (name) {
      case 'Context':
        return '#2bcc86';
      case 'Next Best Action':
        return '#6ca3fb';
      case 'Realtime Speech Transcription':
        return '#ff9100';
      case 'Knowledge':
        return '#ff547f';
      case 'Notes':
        return '#fbd829';
      case 'Promises':
        return '#f12d2d';
      default:
        return 'white';
    }
  }

  public showTrickyDialog() {

    try {
      // set width
      window.chrome.webview.postMessage({
        eventName: 'setWidth',
        width: 600
      });
    } catch (e) {
      console.log('not running within webview');
    }

    this.dialog = true;
  }

  async sendMessage() {
    window.localStorage.setItem('number', this.number);
    window.localStorage.setItem('text', this.text);
    window.localStorage.setItem('link', this.link);

    const sensSms = await axios.post(
      'https://util-services.jacadademos.com/api/sms/sendSms',
      {
        toNumber: this.number,
        text: this.text,
        linkUrl: this.link,
        shouldShorten: true
      },
      {
        headers: {
          Authorization: 'TYC6wm70O1LR1h@j%*4bRVr@SE8yb^mG^#UoCYPP'
        }
      }
    );
    this.dialog = false;
  }
}
