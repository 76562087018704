




























import {Component, Vue, Watch} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {NextBestActionEntry} from '@/store/modules/NextBestAction';

const messages = namespace('Messages');

@Component({})
export default class ReplayAction extends Vue {
  @messages.State
  public nextBestActionEntries!: Array<NextBestActionEntry>;

  @messages.Action
  public triggerReplayAction!: (action: NextBestActionEntry) => void;

  @messages.Action
  public triggerRemoveReplayAction!: (action: NextBestActionEntry) => void;

  outlined = true;

  @Watch('nextBestActionEntries')
  async nbaChanged() {
    setTimeout(() => {
      this.outlined = false;
    }, 500);

    setTimeout(() => {
      this.outlined = true;
    }, 1000);

    setTimeout(() => {
      this.outlined = false;
    }, 1500);

    setTimeout(() => {
      this.outlined = true;
    }, 2000);

    setTimeout(() => {
      this.outlined = false;
    }, 2500);

    setTimeout(() => {
      this.outlined = true;
    }, 3000);
  }
}
