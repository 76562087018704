




import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { TranscriptionMessageEntry } from '@/store/modules/Transcription';
import { NextBestActionEntry } from '@/store/modules/NextBestAction';
import { GuidanceEntry } from '@/store/modules/Guidance';
import moment from 'moment';

const transcription = namespace('Transcription');
const nextbestaction = namespace('NextBestAction');
const guidance = namespace('Guidance');
const messages = namespace('Messages');

@Component({})
export default class Tester extends Vue {
  @transcription.Action
  private addMessage!: (message: TranscriptionMessageEntry) => void;

  @nextbestaction.Action
  private addNextBestAction!: (message: NextBestActionEntry) => void;

  @nextbestaction.Action
  private startCallAction!: () => void;

  @messages.Action
  private beginChat!: () => void;

  @guidance.Action
  private addAndStart!: (script: GuidanceEntry) => void;

  @messages.Action
  public addNextBestActionEntry!: (entry: NextBestActionEntry) => void;

  mounted() {
    this.sendCallminerStartEvent();

    //this.sendNextBestActionMessages();
    //this.sendTranscriptionMessages();
    /*

    for (let i = 0; i < 30; i++) {
      this.addNextBestActionEntry({
        name: 'Test' + i,
        recognizedText: 'test'
      });
    }

    this.addAndStart({
      name: "Test Guidance",
      script: "http://test.de"
    });

 */
  }

  sendNextBestActionMessages() {
    // some text recognized from the voice provider + matched intent returned a script
    this.addNextBestAction({
      name: 'Recognized Intent',
      script: 'https://test.de',
      recognizedText: 'That´t what I understood'
    });

    // some text recognized from the voice provider + only an answer text from interact
    this.addNextBestAction({
      name: 'Another recognized intent',
      questionText: 'This is my question',
      answerText: 'Here is my answer with no script'
    });

    // some text recognized from the voice provider + answer text and script from interact
    this.addNextBestAction({
      name: 'Yet Another recognized intent',
      questionText: 'This is my question',
      answerText: 'Here is my answer with a script',
      script: 'https://test.de'
    });

    this.addNextBestAction({
      name: 'Yet Another recognized intent 1',
      questionText: 'This is my question',
      answerText: 'Here is my answer with a script',
      script: 'https://test.de'
    });
  }

  sendTranscriptionMessages() {
    const now = moment();

    this.addMessage({
      message: 'Test from agent',
      from: 'agent',
      sentiment: 1,
      sendAt: now.add(10, 'seconds').unix()
    });
    this.addMessage({
      message: 'Test from customer',
      from: 'customer',
      sentiment: 0,
      sendAt: now.add(11, 'seconds').unix()
    });
  }

  private sendCallminerStartEvent() {
    this.beginChat();
  }
}
