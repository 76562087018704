











import {Component, Vue} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {EventService} from '@/services/interact/EventService';

import service from '@/services/twilio-realtime-speech.service';
import {SpeechEvent} from '@/services/types/speech';
import Pubnub from "pubnub";

//import { namespace } from 'vuex-class';
const toolbarOption = namespace('ToolbarOption');
const InteractEventsNS = namespace('InteractEvents');
const transcription = namespace('Transcription');
const messages = namespace('Messages');


@Component({})
export default class TwilioTelephony extends Vue {
  private url = 'https://flex.twilio.com/agent-desktop/';

  pubnub: Pubnub;

  created() {
    window.addEventListener('message', this.receivePostMessage, false);

    this.pubnub = new Pubnub({
      subscribeKey: "sub-c-7eb1b332-eaf8-11eb-ad49-d27c727b4015",
      publishKey: "pub-c-be9ea3b0-4070-4886-b319-6b43eb70adde",
      uuid: "agent_assistant",
    });


  }

  @transcription.Action
  private subscribeToTwilioTranscript!: () => void;

  // used to be nextBestAction
  @messages.Action
  private subscribeToTwilioIntent!: () => void;

  @InteractEventsNS.State
  eventService!: EventService;

  @toolbarOption.Action
  private setTelephonyActive!: (status: boolean) => void;

  @messages.Action
  private setCallerId!: (callerId: string) => void;

  @messages.Action
  private beginChat!: (speechEvent?: SpeechEvent) => void;

  private hangUpCall() {
    const frame: HTMLIFrameElement = document.getElementById('agentAssistantIframe') as HTMLIFrameElement;
    if (frame.contentWindow) {
      frame.contentWindow.postMessage(
          {
            eventName: 'callEnded'
          },
          '*'
      );
      console.log('Received call ended from cti');
      if (this.$store.state.onCall) {
        this.$root.$emit('openAssistant');
      }
    }
  }

  private async receivePostMessage(event: { data: { eventName: string, callerId?: string } }) {

    try {
      if (event && event.data && event.data.eventName) {

        console.log(event.data);

        switch (event.data.eventName) {
          case 'incomingTask':
            this.setTelephonyActive(true);
            this.setCallerId(event.data.callerId!);

            if (event.data.callerId) {

              const fakeEvent = {
                id: 1,
                description: '',
                name: 'twilio_event_topic',
                value: event.data.callerId.replace('+', '00')
              };


              await this.beginChat({
                name: 'start',
                sendAt: Date.now()
              });

              this.pubnub.publish({
                channel: 'agent_assistant',
                message: {
                  eventType: 'call started'
                },
              });


              await service.startListening(this.eventService, {
                description: '',
                features: [],
                id: 0,
                name: '',
                configItems: [fakeEvent]
              });

              console.log('after start listening');

              this.subscribeToTwilioIntent();

              this.subscribeToTwilioTranscript();

            }

            break;
          case 'taskAccepted':
            this.$store.dispatch('startCall');
            this.$root.$emit('openAssistant');
            break;
          case 'hangUpCall':
            this.hangUpCall();
            break;
          case 'taskComplete':
            this.$store.dispatch('openTelephony');
            break;
        }
      }
    } catch (e) {
      console.error(e);
    }
  }
}
