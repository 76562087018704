import axios from 'axios';
import { Feedback, InteractConfig, Message, thinkingHTML } from '@/types';
import { v4 } from 'uuid';
import store from '@/store';
import InteractChatConfig from '@/store/modules/InteractChatConfig';
import { InteractSocketApi } from '@/api/InteractSocketApi';
import * as _ from 'lodash';

export class InteractAIAPI {
  private readonly baseUrl = 'https://gointeract.io/interact/version/1/account/';
  private interactionInstanceId = '';
  private userId = '';
  private config: InteractConfig;

  constructor(config: InteractConfig) {
    this.config = config;
  }

  private addThinkingMessage() {
    const thinkingMessage = {
      source: 'BOT',
      timestamp: new Date().getTime(),
      html: thinkingHTML,
      type: 'THINKING'
    };
    store.dispatch('Messages/addMessage', thinkingMessage);
  }

  public async init(config: InteractConfig, externalSessionIdName?: string, externalSessionId?: string) {
    this.config = config;

    localStorage.removeItem('chatSessionId');
    delete this.config.chatSessionId;

    if (localStorage.getItem('chatbot_userId')) {
      this.userId = localStorage.getItem('chatbot_userId')!;
    } else {
      this.userId = v4();
      localStorage.setItem('chatbot_userId', this.userId);
    }

    const headers: any = {
      'Tenant-Id': this.config.tenantId,
      'Application-Key': this.config.apiKey,
      'Environment-Name': this.config.environment,
      'Content-Type': 'application/json',
      'User-Id': this.userId
    };

    const data: any = {
      accountId: this.config.tenantId,
      appkey: this.config.apiKey,
      domainName: this.config.domainName,
      'Environment-Name': this.config.environment,
      variables: {
        // eslint-disable-next-line @typescript-eslint/camelcase
        AA_CallerId: store.state.Messages.callerId
      }
    };

    if (externalSessionId && externalSessionIdName) {
      data.variables[externalSessionIdName] = externalSessionId;
    }

    if (store.state.DemoConfig.configuredVariables) {
      store.state.DemoConfig.configuredVariables.map((value: { key: string; value: string }) => {
        data.variables[value.key] = value.value;
      });
    }

    if (this.config.chatSessionId) {
      console.log('Using Session Context ID');
      headers['session-hint'] = this.config.chatSessionId;
      data.sessionId = this.config.chatSessionId;
    }

    const appSettingsUrl = `https://gointeract.io/interact/appkeydata/settings/${this.config.apiKey}?includeAppTranslation=false`;
    const settings = await axios.get(appSettingsUrl, {
      headers: headers,
      responseType: 'json'
    });

    if (settings.data.theme.commonCssUrl) {
      console.log('Found common css');
      const style = document.createElement('link');
      style.href = settings.data.theme.commonCssUrl;
      style.type = 'text/css';
      style.rel = 'stylesheet';
      document.head.appendChild(style);
    }

    if (settings.data.rpaBotRunMode === 'Attended') {
      try {
        const botIdRes = await axios.get('http://127.0.0.1:9002/rpa/bot/id');
        headers['rpa-bot-id'] = botIdRes.data; // TODO - Check if we need to send teh rpa-bot-id for each request or if the session is good enough
        store.dispatch('InteractChatConfig/updateBotId', botIdRes.data).then((value) => {
          console.log('bot id updated');
        });
      } catch (e) {
        console.warn('No bot available', e);
      }
    }

    const res = await axios.post(
      this.baseUrl + this.config.tenantId + '/conversation/domains/' + this.config.domainName + '/start',
      data,
      {
        headers: headers,
        responseType: 'json'
      }
    );

    window.localStorage.setItem('jacadaChatSessionId', res.data.sessionId);

    this.config.chatSessionId = res.data.sessionId;
    this.config.chatSessionUniqueId = res.data.instanceUniqueId;

    // update config
    store.dispatch('InteractChatConfig/updateChatSessionId', res.data.sessionId);
    store.dispatch('InteractChatConfig/updateChatSessionUniqueId', res.data.instanceUniqueId);

    this.interactionInstanceId = res.data.interactionInstanceId;

    if (res.data.greetingResponse && res.data.pastConversationsMessages.length === 0) {
      const message: Message = {
        source: 'BOT',
        timestamp: new Date().getTime(),
        type: 'GREETING'
      };

      if (res.data.greetingResponse.html) {
        message.html = res.data.greetingResponse.html;
      } else if (res.data.greetingResponse.interactResponse.elementResponse.page.pageContent.contentSections) {
        message.pageContent =
          res.data.greetingResponse.interactResponse.elementResponse.page.pageContent.contentSections;
        message.pageHeader = res.data.greetingResponse.interactResponse.elementResponse.page.pageContent.contentHeader;
      }

      store.dispatch('Messages/startChat', message);
    } else if (
      res.data.pastConversationsMessages.length > 0 &&
      store.state.InteractChatConfig.config.mode !== 'MultiModal' &&
      'false'.length == 1 // remove past conversations!
    ) {
      let date = new Date(0);
      res.data.pastConversationsMessages.forEach((msg: any) => {
        const msgDate = new Date(msg.timestamp);
        if (msgDate.toDateString() !== date.toDateString()) {
          date = msgDate;
          const dateMessage: Message = {
            timestamp: msg.timestamp,
            stringMessage: msgDate.toDateString(),
            source: 'BOT',
            type: 'TIME'
          };

          store.dispatch('Messages/addMessage', dateMessage);
        }

        if (msg.type === 'BOT_MSG') {
          store.dispatch('Messages/handleResponse', {
            data: msg,
            type: 'PAST'
          });
        } else if (msg.type === 'USER_MSG') {
          const message: Message = {
            timestamp: msg.timestamp,
            stringMessage: msg.text,
            source: 'USER',
            type: 'PAST'
          };

          store.dispatch('Messages/addMessage', message);
        }
      });

      if (res.data.greetingResponse) {
        const message: Message = {
          source: 'BOT',
          timestamp: new Date().getTime(),
          type: 'GREETING'
        };

        if (res.data.greetingResponse.html) {
          message.html = res.data.greetingResponse.html;
        } else if (res.data.greetingResponse.interactResponse.elementResponse.page.pageContent.contentSections) {
          message.pageContent =
            res.data.greetingResponse.interactResponse.elementResponse.page.pageContent.contentSections;
          message.pageHeader =
            res.data.greetingResponse.interactResponse.elementResponse.page.pageContent.contentHeader;
        }

        store.dispatch('Messages/startChat', message);
      }
    }

    if (InteractChatConfig.state.config.mode !== 'StandAlone') {
      const socketApi = new InteractSocketApi(store.state.InteractChatConfig.config);
      try {
        socketApi.connect()?.then(() => {
          console.log('Connected to Interact Socket');
          socketApi.subscribe('chat-' + InteractChatConfig.state.config.chatSessionId, (msg) => {
            if (
              store.state.Messages.messages[store.state.Messages.length - 1] &&
              store.state.Messages.messages[store.state.Messages.length - 1].type === 'THINKING'
            ) {
              console.log('Skipping my own message');
              return;
            }
            const receivedMsg = JSON.parse(JSON.parse(msg.body.toString()).data);
            console.log('Received Socket Message:', receivedMsg);
            store.dispatch('Messages/handleResponse', receivedMsg);
          });
        });
      } catch (e) {
        console.error(e);
      }
    }
  }

  public sendMessage(message: Message) {
    this.addThinkingMessage();
    const headers: any = {
      'Tenant-Id': this.config.tenantId,
      'Application-Key': this.config.apiKey,
      'Environment-Name': this.config.environment,
      'Content-Type': 'application/json'
    };

    const body: any = {
      text: message.stringMessage,
      variables: {
        channel: 'richUI',
        demoChannel: 'richUI',
        'Feedback Collection': JSON.stringify(message.feedback),
        // eslint-disable-next-line @typescript-eslint/camelcase
        AA_CallerId: store.state.Messages.callerId
      }
    };

    if (store.state.DemoConfig.configuredVariables) {
      store.state.DemoConfig.configuredVariables.map((value: { key: string; value: string }) => {
        body.variables[value.key] = value.value;
      });
    }

    return axios.post(
      this.baseUrl +
        this.config.tenantId +
        '/conversation/domains/' +
        this.config.domainName +
        '?sessionId=' +
        this.config.chatSessionId,
      body,
      {
        headers: headers,
        responseType: 'json'
      }
    );
  }

  public sendHiddenMessage(message: Message, variables?: any) {
    const headers: any = {
      'Tenant-Id': this.config.tenantId,
      'Application-Key': this.config.apiKey,
      'Environment-Name': this.config.environment,
      'Content-Type': 'application/json'
    };

    const body: any = {
      text: message.stringMessage,
      variables: {
        channel: 'richUI',
        demoChannel: 'richUI',
        'Feedback Collection': JSON.stringify(message.feedback),
        // eslint-disable-next-line @typescript-eslint/camelcase
        AA_CallerId: store.state.Messages.callerId
      }
    };

    if (store.state.DemoConfig.configuredVariables) {
      store.state.DemoConfig.configuredVariables.map((value: { key: string; value: string }) => {
        body.variables[value.key] = value.value;
      });
    }

    if (variables) {
      body.variables = _.merge(body.variables, variables);
    }

    axios
      .post(
        this.baseUrl +
          this.config.tenantId +
          '/conversation/domains/' +
          this.config.domainName +
          '?sessionId=' +
          this.config.chatSessionId,
        body,
        {
          headers: headers,
          responseType: 'json'
        }
      )
      .then();
  }

  public returnToNlp(backToNlpText: string, feedback: Array<Feedback>) {
    if (backToNlpText !== 'receive_evaluation_from_client') {
      this.addThinkingMessage();
    }

    const headers: any = {
      'Tenant-Id': this.config.tenantId,
      'Application-Key': this.config.apiKey,
      'Environment-Name': this.config.environment,
      'Content-Type': 'application/json'
    };

    const body: any = {
      conversationActionDto: {
        actionName: 'RETURN_TO_NLP',
        actionValues: {
          backToNlpText: backToNlpText
        }
      },
      inputParameters: {},
      variables: {
        'Feedback Collection': JSON.stringify(feedback)
      }
    };

    return axios.post(
      this.baseUrl +
        this.config.tenantId +
        '/conversation/domains/' +
        this.config.domainName +
        '?sessionId=' +
        this.config.chatSessionId,
      body,
      {
        headers: headers,
        responseType: 'json'
      }
    );
  }

  public continueFlow(inputParameters: any, feedback: Array<Feedback>) {
    this.addThinkingMessage();

    const headers: any = {
      'Tenant-Id': this.config.tenantId,
      'Application-Key': this.config.apiKey,
      'Environment-Name': this.config.environment,
      'Content-Type': 'application/json'
    };

    const body: any = {
      conversationActionDto: {
        actionName: 'CONTINUE_FLOW'
      },
      inputParameters: inputParameters,
      variables: {
        'Feedback Collection': JSON.stringify(feedback)
      }
    };

    return axios.post(
      this.baseUrl +
        this.config.tenantId +
        '/conversation/domains/' +
        this.config.domainName +
        '?sessionId=' +
        this.config.chatSessionId,
      body,
      {
        headers: headers,
        responseType: 'json'
      }
    );
  }

  public goToFlow(flowId: string, feedback?: Array<Feedback>) {
    //this.addThinkingMessage();

    const headers: any = {
      'Tenant-Id': this.config.tenantId,
      'Application-Key': this.config.apiKey,
      'Environment-Name': this.config.environment,
      'Content-Type': 'application/json'
    };

    const body: any = {
      conversationActionDto: {
        actionName: 'GO_TO_FLOW',
        actionValues: {
          flowId: flowId
        }
      },
      inputParameters: {},
      variables: {
        //'Feedback Collection': JSON.stringify(feedback)
      }
    };

    if (feedback) {
      body.variables['Feedback Collection'] = JSON.stringify(feedback);
    }

    return axios.post(
      this.baseUrl +
        this.config.tenantId +
        '/conversation/domains/' +
        this.config.domainName +
        '?sessionId=' +
        this.config.chatSessionId,
      body,
      {
        headers: headers,
        responseType: 'json'
      }
    );
  }

  public uploadResource(file: File, id: string, headerId: string) {
    console.log('Id in api', id);

    const headers: any = {
      'Tenant-Id': this.config.tenantId,
      'Application-Key': this.config.apiKey,
      'Environment-Name': this.config.environment,
      'Content-Type': 'multipart/form-data',
      'File-Local-Address': encodeURI('' + new Date().getTime()),
      'Object-Step-Id': headerId,
      'User-Id': this.config.chatSessionUniqueId
    };

    const formData = new FormData();
    formData.append('file', file);

    return axios.post(
      this.baseUrl +
        this.config.tenantId +
        '/interaction/dummy/' +
        this.interactionInstanceId +
        '/' +
        id +
        '/resourceUpload?sessionId=' +
        this.config.chatSessionId,
      formData,
      {
        headers: headers,
        responseType: 'json'
      }
    );
  }

  public sendMessageWithVariables(text: string, variables: any) {
    const headers: any = {
      'Tenant-Id': this.config.tenantId,
      'Application-Key': this.config.apiKey,
      'Environment-Name': this.config.environment,
      'Content-Type': 'application/json'
    };

    variables.channel = 'richUI';
    variables.demoChannel = 'richUI';

    const body: any = {
      text: text,
      variables: variables
    };

    if (store.state.DemoConfig.configuredVariables) {
      store.state.DemoConfig.configuredVariables.map((value: { key: string; value: string }) => {
        body.variables[value.key] = value.value;
      });
    }

    return axios.post(
      this.baseUrl +
        this.config.tenantId +
        '/conversation/domains/' +
        this.config.domainName +
        '?sessionId=' +
        this.config.chatSessionId,
      body,
      {
        headers: headers,
        responseType: 'json'
      }
    );
  }
}
