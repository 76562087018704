import { render, staticRenderFns } from "./MessageBubble.vue?vue&type=template&id=1d131eb6&"
import script from "./MessageBubble.vue?vue&type=script&lang=ts&"
export * from "./MessageBubble.vue?vue&type=script&lang=ts&"
import style0 from "./MessageBubble.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VScrollYReverseTransition } from 'vuetify/lib/components/transitions';
installComponents(component, {VCard,VScrollYReverseTransition})
