import { HubConnectionBuilder, LogLevel } from '@aspnet/signalr';
import { MessagePackHubProtocol } from '@aspnet/signalr-protocol-msgpack';
import axios from 'axios';
import { Subject } from 'rxjs';
import { SpeechEvent } from '@/services/types/speech';
import { DemoConfigItem } from '@/services/demo-config.service';
import { EventService } from '@/services/interact/EventService';

export class TwilioRealtimeSpeechService {
  private subject: Subject<SpeechEvent> | undefined;
  private eventService!: EventService;

  public async startListening(
    eventService: EventService,
    demoConfigItem?: DemoConfigItem
  ): Promise<Subject<SpeechEvent>> {
    this.eventService = eventService;
    let listenTo = '';
    if (demoConfigItem) {
      demoConfigItem.configItems.map((value) => {
        if (value.name === 'twilio_event_topic') {
          listenTo = value.value;
        }
      });
    }
    const subject = new Subject<SpeechEvent>();

    if (listenTo === '') {
      console.warn('Twilio Speech not configured. Are you missing the config time "twilio_event_topic"?');
    } else {
      this.eventService.subscribe('/' + listenTo + '/speechEvents', (message) => {
        const body = JSON.parse(message.body);
        const eventData = JSON.parse(body.data) as SpeechEvent;
        console.log('twilio speech event', eventData);

        subject.next(eventData);
      });
    }

    this.subject = subject;

    return subject;
  }

  public getSpeechEvents(): Subject<SpeechEvent> | undefined {
    console.log('getSpeechEvents', this.subject);
    return this.subject;
  }
}

const service = new TwilioRealtimeSpeechService();

export default service;
